'use strict';

import Vue from 'vue'
import store from 'client/store'
import config from "@/config";

const concat = require("lodash/concat");
const filter = require("lodash/filter");
const find = require("lodash/find");
const forEach = require("lodash/forEach");
const map = require("lodash/map");
const sortBy = require("lodash/sortBy");

import moment from 'moment'

import participantLib from '@/lib/participant'
import planningLib from '@/lib/planning'
import sessionLib from '@/lib/session'

import sessionMain from '/lib/session'

export default {
  /**
   *
   * @param block
   * @param options
   * @returns {string}
   *
   * options:
   * * full: include panel, set nr
   * * addExerciseType
   * * rotation
   */
  getBlockTitle: function(block, options = {}) {
    const session = find(store.state.sessions.items, i => i.id === block.sessionId)
    options.rotationType = sessionLib.getSessionRotationTypeFull(session)
    const exerciseTypes = map(store.getters.sessionExerciseTypes(session, true), item => item.id)
    return sessionMain.getBlockTitle(block, session, options, exerciseTypes, key => Vue.i18n.t(key))
  },

  /** find which apparatus a block of gymnasts is on in specific rotation (rotation type is rotation).
   * Take into account:
   * - rest rotations
   * - exercise type select in session
   */
  getBlockExerciseType: function(session, block, rotation = 0) {
    const rotationType = sessionLib.getSessionRotationTypeFull(session)
    const exerciseTypes = map(store.getters.sessionExerciseTypes(session, true), item => item.id)
    return sessionMain.getBlockExerciseType(session, block, rotationType, exerciseTypes, rotation)
  },

  getMixedOrderPanel: function(blockParticipation, participation, session) {
    const sessionCategory = find(session.categories, sessionCategory => {
      return participation.categoryId === sessionCategory.categoryId;
    });

    if (sessionCategory) {
      const sessionCategoryExercise = find(sessionCategory.exercises, sessionCategoryExercise => {
        return blockParticipation.exerciseTypeId === sessionCategoryExercise.exerciseTypeId;
      });
      if (sessionCategoryExercise) {
        return sessionCategoryExercise.set;
      }
    }

    return false
  },

  checkMixed: function(blockParticipation, participation, session, panel) {
    if (! panel) {
      return true;
    }

    const exercisePanel = this.getMixedOrderPanel(blockParticipation, participation, session)
    return exercisePanel === panel.set
  },

  getPanelOrder: function(panel, rotation) {
    const session = find(store.state.sessions.items, item => item.id === panel.sessionId)
    const blocks = filter(store.state.blocks.items, item => item.sessionId === session.id)

    const rotations = sessionLib.getSetRotations(session, panel.set)

    if (rotation >= rotations) {
      return {}
    }

    const rotationType = sessionLib.getSessionRotationType(panel.sessionId)

    const settings = find(store.state.panels.configurations, item => item.panelId === panel.id)

    return map(settings.passes, pass => {
      let passSet = pass.set
      let exerciseTypeId = pass.exerciseTypeId
      let blockIndex = store.getters.panelRotationBlockIndex(session, panel, rotation)

      const block = find(blocks, item => item.set === passSet && item.index === blockIndex)

      const blockParticipations = filter(this.getBlockParticipations(block.id), item => {
        const part = participantLib.getParticipation(item.participationId)
        if (! part) return false

        if (rotationType === 'mixed') {
          return this.checkMixed(item, part, session, panel)
        }
        return true
      })
      const blockParticipationsSorted = this.orderBlockParticipations(blockParticipations, block, session, rotation)

      return {
        exerciseTypeId: exerciseTypeId,
        block: block,
        participations: blockParticipationsSorted,
      }
    })
  },

  getBlockParticipations: function(blockId) {
    let blockParticipations = filter(store.state.blockParticipations.items, item => item.blockId === blockId)
    return sortBy(blockParticipations, 'index')
  },

  getBlockPartsPerRotation: function(block, rotation) {
    const blockRotation = find(block.rotationOrders, item => {
      return item.rotation === rotation;
    });

    if (! blockRotation) {
      return [];
    }

    return map(blockRotation.participations, participationId => {
      return {
        participationId: participationId,
      }
    });
  },

  orderBlockParticipations: function(blockParticipations, block, session, activeRotation) {
    const rotationType = sessionLib.getSessionRotationTypeFull(session)
    const exercises = store.getters.sessionExerciseTypes(session, true)
    return sessionMain.orderBlockParticipations(
      store.state.participations.items, blockParticipations, block, session, rotationType, exercises, activeRotation)
  },

  getBlockParticipationExerciseType: function (blockParticipation, passExerciseTypeId) {
    return blockParticipation.exerciseTypeId ? blockParticipation.exerciseTypeId : passExerciseTypeId
  },

  assembleBlockItem: function(config, blockParticipation, index, session, discipline, context, exerciseTypeId = null) {
    const data = {}
    if (blockParticipation.filler) {
      data.filler = 'filler'
      data.panel = context.previous.panel
      data.exerciseTime = context.previous.exerciseTime
    } else {
      const participation = participantLib.getParticipation(blockParticipation.participationId)
      const teamParticipation = participantLib.getTeamParticipation(participation)
      const participant = participantLib.getParticipant(participation)
      const category = participantLib.getCategory(participation);
      const exerciseType = exerciseTypeId ? exerciseTypeId : blockParticipation.exerciseTypeId
      let exerciseName = exerciseTypeId ? exerciseTypeId : blockParticipation.exerciseTypeId ?
        participantLib.getExerciseLabel(participation, session.roundIndex, blockParticipation.exerciseTypeId) : null
      const panel = blockParticipation.exerciseTypeId ?
        this.deriveSet(session, category.id, blockParticipation.exerciseTypeId) : null
      const exerciseTime =
        planningLib.getExerciseTime(config, session.roundIndex, category.id, exerciseType)

      data.participantId = participant.id
      data.bib = (participation.bib ? participation.bib : '') + (teamParticipation?.bib ? '/' + teamParticipation.bib : '')
      data.clubName = participantLib.getClubName(participation, participant, context.addCountry)
      data.categoryName = category.name
      data.name = participantLib.getName(participation)
      data.names = participantLib.getParticipantNames(participation)
      data.group = category.participantType === 'group'
      data.panel = panel
      data.exerciseType = exerciseType
      data.exerciseName = exerciseName
      data.exerciseTime = exerciseTime
      data.absent = participantLib.getStatus(participation, session.roundIndex) !== 'present'
    }

    if (context.time) {
      if (index === 0 ? context.addExerciseTimeGap : index < context.addExerciseTime) {
        context.time.add(data.exerciseTime, 's')
      } else if (context.time && context.previous.panel !== 0 && context.previous.panel === data.panel) {
        context.time.add(config.judgeTime, 's')
      }

      data.zones = context.zoneTimes.map(t => moment(context.time).add(-t, 'm').format('HH:mm'))

      data.timeRaw =  context.time?.isValid() ? moment(context.time) : null
      data.time = context.time?.isValid() ? context.time.format('HH:mm') : ''

      context.time.add(data.exerciseTime, 's')
    }

    return data;
  },

  deriveSet: function(session, categoryId, exerciseTypeId) {
    return sessionMain.deriveSet(session, categoryId, exerciseTypeId)
  },

  prepareBlockMixedTiming: function(block, session, context) {
    const data = {
      blocks: store.state.blocks.items,
      blockParticipations: store.state.blockParticipations.items,
      discipline: store.state.eventDiscipline.discipline,
      exerciseTypes: store.state.eventDiscipline.exerciseTypes,
    }
    const {addStartGap, addExerciseTime} =
      sessionMain.prepareBlockMixedTiming(block, session, data)

    context.addExerciseTimeGap = addStartGap
    context.addExerciseTime = addExerciseTime
  },

  assembleBlock: function(block, startTime, session, discipline, rotation = -1, set,
                          blockIndex, forExport = false, exerciseTypeId = null) {
    const rotationType = sessionLib.getSessionRotationType(block.sessionId)
    const exercise = this.getBlockExerciseType(session, block, rotation >= 0 ? rotation : 0)
    if (session.rotationType === 'schedule' && exercise === 'rest') {
      return null
    }

    const eventDiscipline = store.state.eventDisciplines.items.find(e => e.id === session.eventDisciplineId)
    const config = planningLib.getPlanningConfig(session.eventDisciplineId)
    const mixed = rotationType === 'mixed'
    const timed = mixed || !! discipline.config.orderTiming
    const groupCategories = ! forExport && ! mixed && ! timed;

    let zoneTimes = []
    if (rotationType === 'mixed') {
      const warmup = config.warmupConfigs.find(w => w.name === session.warmup)
      if (warmup?.type === 'scheduled') {
        let time = 0
        zoneTimes = warmup.zones.slice().reverse().map(z => time += z.time).reverse()
      }
    }

    let blockParts = this.getBlockParticipations(block.id)
    if (rotation >= 0) {
      blockParts = this.orderBlockParticipations(blockParts, block, session, rotation)
    }

    const context = {
      rotationType,
      time: startTime,
      previous: {},
      addExerciseTime: false,
      set,
      zoneTimes,
      addCountry: eventDiscipline.clubSymbols === 'flags'
    }
    this.prepareBlockMixedTiming(block, session, context)
    console.log('prepare block timing', context)

    let items = map(blockParts, (blockPart, index) => {
      const blockItem = this.assembleBlockItem(config, blockPart, index, session, discipline, context, exerciseTypeId)
      context.previous = blockItem
      return blockItem
    });

    if (groupCategories) {
      let cat = '';
      let groupedItems = [];
      forEach(items, item => {
        if (item.categoryName !== cat) {
          groupedItems.push({
            category: item.categoryName,
          });
          cat = item.categoryName;
        }
        groupedItems.push(item);
      });
      items = groupedItems;
    }

    let title = this.getBlockTitle(block, {full: true, addExerciseType: true, rotation: rotation >= 0 ? rotation : 0})

    return {
      title,
      block: block.index+1,
      items,
    };
  },

  getRotationStartTime(session, rotation) {
    return sessionMain.getRotationStartTime(session, rotation)
  },

  setIterationStartTime(session, rotation, iteration, iterationStartTime) {
    return sessionMain.setIterationStartTime(session, rotation, iteration, iterationStartTime)
  },

  prepareSections(discipline, session, blocks, set = 0, forExport, panels = null, panelConfigs = null) {
    const rotationType = sessionLib.getSessionRotationTypeFull(session)
    console.log('prepare sections', rotationType)
    let sections = [];

    let title = moment(session.date).format("dddd DD-MM-YYYY") + ' ' + session.name + ' ' + Vue.i18n.t('session.order')
    if (set) {
      title += ' - ' + Vue.i18n.t('set') + ' ' + set;
    }

    const timed = discipline.config.orderTiming && rotationType !== 'mixed'

    let zones = []
    if (rotationType === 'mixed') {
      const config = planningLib.getPlanningConfig(session.eventDisciplineId)
      const warmup = config.warmupConfigs.find(w => w.name === session.warmup)
      if (warmup?.type === 'scheduled') {
        zones = warmup.zones.map(z => z.label)
      }
    }

    if (session.rotationType === 'schedule') {
      for (let r = 0; r < session.rotations; r++) {
        const rotationStartTime = this.getRotationStartTime(session, r)
        let blockData = [];
        for (let b = 0; b < session.rotations; b++) {
          const blockIndex = (session.rotations + b - r) % session.rotations;
          const block = find(blocks, item => {
            return item.index === blockIndex;
          });
          const blockTmp = this.assembleBlock(block, rotationStartTime, session, discipline, r, set, b, forExport)
          if (blockTmp) {
            blockData.push(blockTmp)
          }
        }

        sections.push({
          title: title + ' ' + Vue.i18n.t('rotation') + ' ' + (r+1),
          session: session.name,
          type: 'workorder-blocks-compact',
          data: {
            blocks: blockData,
            timing: sessionLib.getSessionTimes(session),
            notes: session.description,
            zones,
          },
        });
        if (! forExport) {
          sections.push({
            type: 'pagebreak',
          });
        }
      }
    }
    else {
      let blockData = []

      if (timed) {
        const exercises = sessionLib.getSessionEffectiveExerciseTypes(session)

        // map sets to panel configurations
        panelConfigs = Array.from(Array(session.sets), (e, i) => {
          const set = i+1
          const panel = panels.find(p => p.sessionId === session.id && p.set === set)
          return panelConfigs.find(c => c.panelId === panel.id)
        })

        for (let r = 0; r < session.rotations; r++) {
          let iterationStartTime = this.getRotationStartTime(session, r)
          for(let iteration = 0; iteration < exercises.length; iteration++) {
            iterationStartTime = this.setIterationStartTime(session, r, iteration, iterationStartTime)
            let setStartTime = moment(iterationStartTime)
            for (let set = 0; set < session.sets; set++) {
              const pass = panelConfigs[set].passes[iteration]
              const block = blocks.find(b => b.index === r && b.set === pass.set)
              let blockStartTime = moment(setStartTime)
              const result = this.assembleBlock(
                block, blockStartTime, session, discipline, -1, set, 0, false, pass.exerciseTypeId)
              if (blockStartTime?.isAfter(iterationStartTime)) {
                iterationStartTime = moment(blockStartTime)
              }
              result.title += ' ' + Vue.i18n.t('exercise.type.' + pass.exerciseTypeId)
              blockData.push(result)
            }
            if (session.sets % 2 && session.sets > 1) {
              blockData.push({})
            }
          }
        }
      }
      else {
        for (let r = 0; r < session.rotations; r++) {
          const rBlocks = blocks.filter(b => b.index === r)
          blockData = concat(blockData, map(rBlocks, block => {
            const rotationStartTime = this.getRotationStartTime(session, block.index)
            return this.assembleBlock(block, rotationStartTime, session, discipline);
          }));
          if (set === 0 && session.sets % 2 && session.sets > 1) {
            blockData.push({})
          }
        }
      }

      if (rotationType === 'mixed') {
        sections.push({
          title,
          session: session.name,
          type: 'workorder-mixed',
          data: {
            blocks: [],
            timing: sessionLib.getSessionTimes(session),
            notes: session.description,
            zones,
          },
        })

        const config = planningLib.getPlanningConfig(session.eventDisciplineId)

        sections = sections.concat(map(blockData, (block, index) => {
          return {
            title: (blockData.length > 1 ? Vue.i18n.t('block') + ' ' + (index+1) : null),
            session: session.name,
            type: 'workorder-mixed',
            data: {
              blocks: [block],
              zones,
              showExerciseTime: ! config.mixedHideExerciseTime,
            },
          }
        }))
      } else {
        let workorder = 'workorder-blocks-compact'
        if (discipline.config.workorder) {
          workorder = discipline.config.workorder
        }

        sections.push({
          title,
          session: session.name,
          type: timed ? 'workorder-blocks-timed' : workorder,
          data: {
            blocks: blockData,
            timing: sessionLib.getSessionTimes(session),
            notes: session.description,
            zones,
          },
        });
      }
    }

    return sections;
  },

  // returns the report section for printing the order of a session
  preparePrint: function(session, forExport = false) {
    return new Promise((resolve, reject) => {
      const discipline = store.state.eventDiscipline.discipline
      const timed = discipline.config.orderTiming

      if (timed) {
        Vue.http.get(config.root + '/sessions/' + session.id + '/loadPanels').then((result) => {
          const sections = this.preparePrintAux(discipline, session, forExport,
            result.data.panels, result.data.configurations)
          resolve(sections)
        }, err => reject(err))
      } else {
        const sections = this.preparePrintAux(discipline, session, forExport)
        resolve(sections)
      }
    })
  },

  preparePrintAux(discipline, session, forExport, panels = null, panelConfigs = null) {
    const rotationType = sessionLib.getSessionRotationType(session.id)
    let sections = []

    const mixSets = rotationType !== 'rotation'
    const sets = mixSets ? 1 : session.sets

    for (let i = 1; i <= sets; i++) {
      let blocks = this.getSessionBlocks(session, rotationType, i, mixSets)
      const set = sets === 1 ? 0 : i
      sections = concat(sections, this.prepareSections(discipline, session, blocks, set, forExport, panels, panelConfigs))
    }

    if (! forExport) {
      if (rotationType === 'mixed') {
        sections.push({
          type: 'pagebreak',
        })
      } else {
        const sections2 = []
        forEach(sections, section => {
          sections2.push(section)
          sections2.push({
            type: 'pagebreak',
          })
        })
        sections = sections2
      }
    }

    return sections
  },

  getSessionBlocks: function(session, rotationType, set, mixSets = false) {
    const sort = rotationType === 'block' ? ['index', 'set'] : ['set', 'index']
    return sortBy(
      filter(store.state.blocks.items, item => item.sessionId === session.id && (mixSets || item.set === set)), sort)
  },
  findSessionBlock: function(session, set, rotation) {
    return find(store.state.blocks.items, item => item.sessionId === session.id && item.set === set && item.index === rotation)
  },
};
